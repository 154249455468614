:root {
  /*
   * New color
   */
  --isp-c-interstellar: #1a1a1a;
  /*
   * New color
   */
  --isp-c-black-cat: #262626;
  /*
   * New color
   */
  --isp-c-darth-vader: #333333;
  /*
   * New color
   * Replaced: #383838 #2d4053
   * Renamed: --isp-dark-gray-2
   */
  --isp-c-stranger-things: #404040;
  /*
   * Renamed: --isp-dark-gray-3
   * Replaced: #585858 #47586a
   */
  --isp-c-creepy-cloud: #595959;
  /*
   * Renamed: --isp-gray-4
   * Replaced: #617283
   * Merged with:
   * --isp-light-gray-15: #707070
   * --isp-gray-3: #5d6f7f
   */
  --isp-c-lovely-puddle: #727272;
  /*
   * Renamed: --isp-light-gray-3
   * Replaced: #979797 #8a99a8
   * Merged with:
   * --isp-light-gray-14: #878787
   */
  --isp-c-grey-weekdays: #999999;
  /*
   * Renamed: --isp-light-gray-1
   * Replaced: #d3d9df
   * Merged with:
   * --isp-light-gray-20: #c2c2c2
   */
  --isp-c-inconspicuous: #d9d9d9;
  /*
   * Renamed: --isp-light-gray-9
   * Replaced: #ebebeb #eeeeee #e2e6ea
   * Replaced:
   * Merged with:
   * --isp-light-gray-10: #e6e6e6
   * --isp-grey-opacity-2: #d2d2d280
   */
  --isp-c-gandalf-the-grey: #e6e6e6;
  /*
   * Renamed: --isp-light-gray-6
   * Replaced: #f4f4f4 #f0f2f4
   */
  --isp-c-russian-north: #f2f2f2;
  /*
   * New color
   */
  --isp-c-moonshine: #f7f7f7;
  /*
   * Renamed: --isp-white
   */
  --isp-c-jon-snow: #ffffff;
  /*
   * New color
   */
  --isp-c-devilman: #2e1f24;
  /*
   * New color
   */
  --isp-c-donor: #7d3641;
  /*
   * Renamed: --isp-pink-8
   * Replaced: #db0444
   * Merged with:
   * --isp-pink-11: #ba0d41
   * !REPLACE ME! Banner danger border: #e96488
   */
  --isp-c-nosferatu: #e50026;
  /*
   * Renamed: --isp-pink-7
   * Replaced: #ff487f
   */
  --isp-c-mabel-pines: #ff4c6a;
  /*
   * New color
   */
  --isp-c-mister-pink: #ff99aa;
  /*
   * Renamed: --isp-light-pink-1
   * Replaced: #fdf3f1
   * Merged with:
   * --isp-red-opacity: #e5499326
   * !REPLACE ME! Banner danger background: #e96488
   */
  --isp-c-natasha-rostova-dress: #fff0f2;
  /*
   * New color
   */
  --isp-c-dead-sun: #2e2c1f;
  /*
   * New color
   */
  --isp-c-hangover: #7d7136;
  /*
   * Renamed: --isp-yellow-3
   * Replaced: #f2a504
   * Merged with:
   * --isp-orange-3: #ff7a1a
   * !REPLACE ME! Banner warning border: #f3845e
   * Link inverse: #ffd100
   */
  --isp-c-c-3po: #ffaa00;
  /*
   * Renamed: --isp-yellow-6
   * Replaced: #ffe03a
   * Merged with:
   * --isp-yellow-7: #f8e71b
   * --isp-yellow-9: #faef66
   * #f8e71c
   * #fff680
   */
  --isp-c-happy-bob: #ffdd33;
  /*
   * New color
   */
  --isp-c-honey-block: #ffee99;
  /*
   * Renamed: --isp-yellow-8
   * Replaced: #fffabb
   * Merged with:
   * --isp-yellow-11: #fef0d8
   * --isp-orange-1: #ffe9cf
   * --isp-orange-2: #fff5ec
   */
  --isp-c-moon-prism-power: #fffbe5;
  /*
   * New color
   */
  --isp-c-gyokuro: #1f2e26;
  /**
   * New color
   */
  --isp-c-green-mile: #367d59;
  /*
   * Renamed: --isp-green-3
   * Replaced: #1d9052
   * Merged with:
   * --isp-green-5: #1c9b7d
   * --isp-cyan-1: #0b9898
   */
  --isp-c-grasshopper: #1fad66;
  /*
   * Renamed: --isp-green-2
   * Replaced: #65c84d
   */
  --isp-c-shire: #26d97f;
  /*
   * New color
   */
  --isp-c-emerald-splash: #a8f0cc;
  /*
   * Renamed: --isp-light-green-1
   * Replaced: #e7fbe8
   * Merged with:
   * --isp-green-opacity: #30ba9a33
   */
  --isp-c-celery-juice: #e9fbf2;
  /*
   * New color
   */
  --isp-c-echo: #172736;
  /*
   * Renamed: --isp-dark-gray-1
   * Replaced: #24262a
   * Merged with:
   * --isp-dark-blue-2: #1f364a
   * rgba(255, 255, 255, 0.2)
   */
  --isp-c-malewicz: #1f3347;
  /*
   * New color
   */
  --isp-c-silent-hill: #36597d;
  /*
   * Renamed: --isp-blue-8
   * Replaced: #075bbe
   */
  --isp-c-deep: #1467b8;
  /*
   * Renamed: --isp-blue-6
   * Replaced: #1a76e2
   * Merged with:
   * --isp-blue-9: #1b88fe
   * --isp-blue-10: #abbafc
   */
  --isp-c-blue-velvet: #1a81e5;
  /*
   * New color
   */
  --isp-c-mood: #4ca7ff;
  /*
   * Renamed: --isp-light-blue-11
   * Replaced: #afcfff
   * Merged with:
   * --isp-blue-12: #58bdf9
   */
  --isp-c-fantomas: #a3ccf5;
  /*
   * Renamed: --isp-light-blue-13 and --isp-c-dream
   * Replaced: #c2daff
   */
  --isp-c-sweet-dreams: #bad9f7;
  /*
   * Renamed: --isp-light-blue-12
   * Replaced: #dbe8ff
   * Merged with:
   * --isp-light-blue-7: #daebff
   * --isp-light-blue-10: #c4e9ff
   */
  --isp-c-blue-frost: #d1e6fa;
  /*
   * Renamed: --isp-light-blue-1, --isp-light-blue-9
   * Replaced: #e9f2ff, #f3f9ff
   * Merged with:
   * --isp--c-baby-tear: #f6fafe
   * --isp-blue-opacity: #0279c026
   * --isp-light-blue-8: #f4f8fe
   */
  --isp-c-crystal-castles: #e8f2fc;
  /*
   * New color
   */
  --isp-c-odysseus: #2eb8b8;
  /*
   * Renamed: --isp-text-color-main-opacity
   * Replaced: #1f364a80
   * Merged with:
   * rgba(52, 74, 94, 0.56)
   * rgba(52, 74, 94, 0.8)
   * !REPLACE ME! Drawers overlay background
   */
  --isp-c-desolation-of-smaug: #0000004d;
  /*
   * New color
   */
  --isp-c-wedding-dress: #ffffffb2;
  --isp-dc-brotherhood-of-steel: var(--isp-c-lovely-puddle);
  --isp-dc-charlie-chaplin: var(--isp-c-inconspicuous);
  --isp-dc-reincarnation: var(--isp-c-gandalf-the-grey);
  --isp-dc-norilsk: var(--isp-c-russian-north);
  --isp-dc-former: var(--isp-c-moonshine);
  --isp-dc-monochrome: var(--isp-c-jon-snow);
  --isp-dc-highway-star: var(--isp-c-malewicz);
  --isp-dc-lapis-lazuli: var(--isp-c-deep);
  --isp-dc-fremen: var(--isp-c-blue-velvet);
  --isp-dc-the-institute: var(--isp-c-fantomas);
  --isp-dc-mass-relay: var(--isp-c-blue-frost);
  --isp-dc-made-in-heaven: var(--isp-c-crystal-castles);
  --isp-dc-cult: var(--isp-c-crystal-castles);
  --isp-dc-sodalite: var(--isp-c-fantomas);
  --isp-dc-aurora-borealis: var(--isp-c-celery-juice);
  --isp-dc-alexandrite: var(--isp-c-emerald-splash);
  --isp-dc-dirty-deeds-done-dirt-cheap: var(--isp-c-natasha-rostova-dress);
  --isp-dc-rhodochrosite: var(--isp-c-mister-pink);
  --isp-dc-bright-emotions: var(--isp-c-nosferatu);
  --isp-dc-new-vegas: var(--isp-c-moon-prism-power);
  --isp-dc-heliodor: var(--isp-c-honey-block);
  --isp-dc-mimic: var(--isp-c-russian-north);
  --isp-dc-cow: var(--isp-c-jon-snow);
  --isp-dc-glass: var(--isp-c-wedding-dress);
  --isp-dc-zebra: var(--isp-c-russian-north);
  color-scheme: light;
}

[data-color-scheme=dark] {
  --isp-dc-brotherhood-of-steel: var(--isp-c-stranger-things);
  --isp-dc-charlie-chaplin: var(--isp-c-stranger-things);
  --isp-dc-reincarnation: var(--isp-c-stranger-things);
  --isp-dc-norilsk: var(--isp-c-darth-vader);
  --isp-dc-former: var(--isp-c-black-cat);
  --isp-dc-monochrome: var(--isp-c-interstellar);
  --isp-dc-highway-star: var(--isp-c-gandalf-the-grey);
  --isp-dc-lapis-lazuli: var(--isp-c-blue-velvet);
  --isp-dc-fremen: var(--isp-c-mood);
  --isp-dc-the-institute: var(--isp-c-silent-hill);
  --isp-dc-mass-relay: var(--isp-c-malewicz);
  --isp-dc-made-in-heaven: var(--isp-c-echo);
  --isp-dc-cult: var(--isp-c-malewicz);
  --isp-dc-sodalite: var(--isp-c-silent-hill);
  --isp-dc-aurora-borealis: var(--isp-c-gyokuro);
  --isp-dc-alexandrite: var(--isp-c-green-mile);
  --isp-dc-dirty-deeds-done-dirt-cheap: var(--isp-c-devilman);
  --isp-dc-rhodochrosite: var(--isp-c-donor);
  --isp-dc-bright-emotions: var(--isp-c-mabel-pines);
  --isp-dc-new-vegas: var(--isp-c-dead-sun);
  --isp-dc-heliodor: var(--isp-c-hangover);
  --isp-dc-mimic: var(--isp-c-black-cat);
  --isp-dc-cow: var(--isp-c-black-cat);
  --isp-dc-glass: var(--isp-c-desolation-of-smaug);
  --isp-dc-zebra: var(--isp-c-interstellar);
  color-scheme: dark;
}

:root {
  --isp-text-color-main: var(--isp-dc-highway-star);
  --isp-checkbox-checked-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13'%3E%3Cpath d='M1 6.435l3.385 4.936L11 1.5' stroke-width='2' stroke='%231A76E2' fill='none' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  --isp-checkbox-checked-image-disabled: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.24 6.54 6.78 12l6.04-9.86' stroke='%23797979' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  --isp-font-weight-bold: 500;
  --isp-border-radius-main: 5px;
  --isp-box-shadow: 0px 1px 4px var(--isp-c-desolation-of-smaug);
  --isp-d-filter-invert: none;
  --isp-balance_used-credit-color: var(--isp-dc-fremen);
  --isp-balance_availabale-credit-color: var(--isp-c-crystal-castles0);
  --isp-balance_unavailabale-credit-color: var(--isp-c-inconspicuous);
  --isp-balance_wait-credit-color: var(--isp-c-happy-bob);
  --isp-balance_late-credit-color: var(--isp-c-mabel-pines);
  --isp-sidebar-width: 275px;
  --isp-topbar-top-line-height: 45px;
  --isp-topbar-height: var(--isp-topbar-top-line-height);
  --ispui-tooltip-ellipsis__text-overflow: clip;
}

[data-color-scheme=dark] {
  --isp-d-filter-invert: invert(1) sepia(2%) saturate(16%) hue-rotate(220deg)
    brightness(113%) contrast(80%);
}

:root {
  --isp-form-preloader-z-index: 1001;
  /** show fullscreen code editor on top of all */
  --isp-z-index-form-code-editor: 1000;
  --isp-progress-modal-z-index: 999;
  --isp-progress-modal-overlay-z-index: 998;
  --isp-z-index-drawer: 997;
  --isp-z-index-form-close-button: 995;
  --ispui-tooltip-z-index: 995;
  --isp-trial-banner-z-index: 200;
  --isp-captcha-z-index: 200;
  --isp-global-search-z-index: 125;
  --isp-form-validation-z-index: 110;
  --isp-autocomplete-select-z-index: 100;
  --isp-progress-bar-z-index: 95;
  --isp-form-header-z-index: 10;
  --isp-form-footer-z-index: 10;
  --isp-global-search-z-index: 125;
  --isp-narrow-banner-danger-z-index: 4;
  --isp-narrow-banner-warning-z-index: 3;
  --isp-narrow-banner-success-z-index: 2;
  --isp-narrow-banner-success-z-index: 1;
}

ispui-progress-bar {
  z-index: 96;
}

/** overrides for ant date-picker */
.ant-picker {
  height: 30px;
  padding: 0 10px 0 10px;
  border-color: var(--isp-date-picker-border-color, var(--isp-dc-charlie-chaplin));
}

.ant-picker-wrapper button {
  font-family: "Ubuntu", sans-serif;
}

.ant-picker-input > input {
  color: var(--isp-dc-highway-star);
  font-family: "Ubuntu", sans-serif;
}

.ant-picker-focused {
  border-color: var(--isp-date-picker-border-color, var(--isp-dc-fremen));
}

.ant-picker:hover:not(.ant-picker-focused) {
  border-color: var(--isp-date-picker-border-color, var(--isp-dc-charlie-chaplin));
}

.ant-picker:hover.ant-picker-focused {
  border-color: var(--isp-date-picker-border-color, var(--isp-dc-fremen));
}

.ant-picker.ant-picker-disabled {
  cursor: default;
  border-color: var(--isp-date-picker-border-color, var(--isp-dc-charlie-chaplin));
  background-color: var(--ispui-input__background-color_disabled, var(--isp-c-russian-north));
}

.ant-picker-input > input[disabled] {
  cursor: default;
  color: var(--ispui-input__text-color_disabled, var(--isp-c-grey-weekdays));
}

.ant-picker-clear {
  top: 55%;
  display: grid;
  width: 20px;
  height: 30px;
  place-items: center;
  color: var(--isp-c-grey-weekdays);
  background-color: var(--isp-dc-monochrome);
}
.ant-picker-clear:hover {
  color: var(--isp-c-lovely-puddle);
}

.ant-picker-dropdown {
  color: var(--isp-dc-highway-star);
}

.ant-picker-panel-container {
  border: 1px solid var(--isp-dc-charlie-chaplin);
  border-radius: 5px;
  background: var(--isp-dc-monochrome);
  box-shadow: 0 1px 4px var(--isp-c-desolation-of-smaug);
}

.ant-picker-panel {
  border: none;
}

.ant-picker-panel .ant-picker-footer {
  border-color: var(--isp-dc-charlie-chaplin);
}

.ant-picker-today-btn {
  cursor: pointer;
  color: var(--isp-c-blue-velvet);
}

.ant-picker-header {
  color: var(--isp-dc-fremen);
  border-color: var(--isp-dc-charlie-chaplin);
}

.ant-picker-header button {
  color: var(--isp-dc-charlie-chaplin);
}

.ant-picker-header > button:hover {
  color: var(--isp-dc-fremen);
}

.ant-picker-header .ant-picker-header-prev-btn,
.ant-picker-header .ant-picker-header-next-btn {
  color: var(--isp-c-grey-weekdays);
}

.ant-picker-header-view .ant-picker-header-year-btn,
.ant-picker-header-view .ant-picker-header-month-btn {
  color: var(--isp-dc-fremen);
}

.ant-picker-content th {
  color: var(--isp-dc-highway-star);
}

.ant-picker-cell {
  color: var(--isp-c-grey-weekdays);
}

.ant-picker-cell .ant-picker-cell-inner {
  border-radius: 5px;
}

.ant-picker-cell-in-view {
  color: var(--isp-dc-highway-star);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--isp-dc-fremen);
  border-radius: 5px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: var(--isp-c-jon-snow);
  background: var(--isp-c-blue-velvet);
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: var(--isp-dc-made-in-heaven);
}

:root {
  --isp-icons-main-color: var(--isp-dc-highway-star);
  --isp-icons-success-color: var(--isp-c-grasshopper);
  --isp-icons-danger-color: var(--isp-dc-bright-emotions);
  --isp-icons-accent-color: var(--isp-c-c-3po);
  --isp-icons-yellow-color: var(--isp-c-happy-bob);
  --isp-icons-fill-color: var(--isp-dc-monochrome);
}

:root {
  --ispui-banner__b-radius: var(--isp-border-radius-main);
  --ispui-banner__bg-color_success: var(--isp-dc-aurora-borealis);
  --ispui-banner__bg-color_warning: var(--isp-dc-new-vegas);
  --ispui-banner__bg-color_danger: var(--isp-dc-dirty-deeds-done-dirt-cheap);
  --ispui-banner__bg-color_info: var(--isp-c-crystal-castles);
  --ispui-banner__icon-img_success: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.5 15a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z' fill='%231FAD66'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.48 4.18a.8.8 0 0 1 .14 1.12L7.47 10.6a.9.9 0 0 1-1.28.15L3.58 8.62a.8.8 0 1 1 1.01-1.24l2.06 1.68 3.7-4.75a.8.8 0 0 1 1.13-.13Z' fill='%23fff'/%3E%3C/svg%3E");
  --ispui-banner__icon-img_warning: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.5 15a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z' fill='%23FA0'/%3E%3Crect x='8.3' y='11' width='1.6' height='5.5' rx='.8' transform='rotate(-180 8.3 11)' fill='%23fff'/%3E%3Crect x='8.3' y='4.6' width='1.6' height='1.6' rx='.8' transform='rotate(-180 8.3 4.6)' fill='%23fff'/%3E%3C/svg%3E");
  --ispui-banner__icon-img_danger: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.63 1.52a1 1 0 0 1 1.74 0l6.28 10.98a1 1 0 0 1-.87 1.5H1.22a1 1 0 0 1-.87-1.5L6.63 1.52Z' fill='%23E5004C'/%3E%3Crect x='8.3' y='9.5' width='1.6' height='5.5' rx='.8' transform='rotate(-180 8.3 9.5)' fill='%23fff'/%3E%3Crect x='8.3' y='12' width='1.6' height='1.6' rx='.8' transform='rotate(-180 8.3 12)' fill='%23fff'/%3E%3C/svg%3E");
  --ispui-banner__icon-img_info: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.5 15a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z' fill='%231980E5'/%3E%3Crect x='8.3' y='11' width='1.6' height='5.5' rx='.8' transform='rotate(-180 8.3 11)' fill='%23fff'/%3E%3Crect x='8.3' y='4.6' width='1.6' height='1.6' rx='.8' transform='rotate(-180 8.3 4.6)' fill='%23fff'/%3E%3C/svg%3E");
}

.ispui-banner_status_success {
  --ispui-banner__b-color: var(--isp-dc-alexandrite);
}

.ispui-banner_status_warning {
  --ispui-banner__b-color: var(--isp-dc-heliodor);
}

.ispui-banner_status_danger {
  --ispui-banner__b-color: var(--isp-dc-rhodochrosite);
}

.ispui-banner_status_info {
  --ispui-banner__b-color: var(--isp-dc-sodalite);
}

.ispui-banner__button-container .ispui-banner__link {
  color: var(--isp-dc-fremen);
}
.ispui-banner__button-container .ispui-banner__link:hover {
  color: var(--isp-dc-lapis-lazuli);
}

:root {
  --ispui-button__line-height: 33px;
  --ispui-button__icon-stroke: var(--isp-dc-fremen);
  --ispui-button__icon-fill: transparent;
  --ispui-button__icon-stroke_hover: var(--isp-c-deep);
  --ispui-button__icon-fill_hover: transparent;
  --ispui-button__icon-stroke_active: var(--isp-dc-fremen);
  --ispui-button__icon-fill_active: transparent;
  --ispui-button__border-radius: var(--isp-border-radius-main);
  --ispui-button__box-shadow: var(--isp-box-shadow);
  --ispui-button__inactive_box-shadow: var(--isp-box-shadow);
  --ispui-button__inactive_text_regular: var(--isp-c-grey-weekdays);
  --ispui-button__inactive_background_regular: var(--isp-dc-norilsk);
  --ispui-button__inactive-icon-stroke: var(--isp-c-grey-weekdays);
  --ispui-button__inactive-icon-fill: transparent;
  --ispui-button__inactive_text_hover: var(--isp-c-grey-weekdays);
  --ispui-button__inactive_background_hover: var(--isp-dc-norilsk);
  --ispui-button__inactive-icon-stroke_hover: var(--isp-c-grey-weekdays);
  --ispui-button__inactive-icon-fill_hover: transparent;
  --ispui-button__inactive_text_active: var(--isp-c-grey-weekdays);
  --ispui-button__inactive_background_active: var(--isp-dc-norilsk);
  --ispui-button__inactive-icon-stroke_active: var(--isp-c-grey-weekdays);
  --ispui-button__inactive-icon-fill_active: transparent;
  --ispui-button__accent_text_regular: var(--isp-c-malewicz);
  --ispui-button__accent_border_regular: transparent;
  --ispui-button__accent_background_regular: var(--isp-c-happy-bob);
  --ispui-button__accent_text_hover: var(--isp-c-malewicz);
  --ispui-button__accent_border_hover: transparent;
  --ispui-button__accent_background_hover: var(--isp-c-honey-block);
  --ispui-button__accent_text_active: var(--isp-c-malewicz);
  --ispui-button__accent_border_active: transparent;
  --ispui-button__accent_background_active: var(--isp-c-happy-bob);
  --ispui-button__third_text_regular: var(--isp-c-jon-snow);
  --ispui-button__third_border_regular: var(--isp-c-jon-snow);
  --ispui-button__third_background_regular: rgba(255, 255, 255, 0.5);
  --ispui-button__third_text_hover: var(--isp-c-jon-snow);
  --ispui-button__third_border_hover: var(--isp-c-jon-snow);
  --ispui-button__third_background_hover: rgba(255, 255, 255, 0.5);
  --ispui-button__third_text_active: var(--isp-c-jon-snow);
  --ispui-button__third_border_active: var(--isp-c-jon-snow);
  --ispui-button__third_background_active: rgba(255, 255, 255, 0.5);
  --ispui-button__primary_text_regular: var(--isp-c-jon-snow);
  --ispui-button__primary_border_regular: transparent;
  --ispui-button__primary_background_regular: var(--isp-c-blue-velvet);
  --ispui-button__primary_text_hover: var(--isp-c-jon-snow);
  --ispui-button__primary_border_hover: transparent;
  --ispui-button__primary_background_hover: var(--isp-c-deep);
  --ispui-button__primary_box-shadow_hover: var(--isp-box-shadow);
  --ispui-button__primary_text_active: var(--isp-c-jon-snow);
  --ispui-button__primary_border_active: transparent;
  --ispui-button__primary_background_active: var(--isp-c-blue-velvet);
  --ispui-button__primary_box-shadow_active: var(--isp-box-shadow);
  --ispui-button__sixth_border-radius: var(--isp-border-radius-main);
  --ispui-button__sixth_text_regular: var(--isp-dc-fremen);
  --ispui-button__sixth_border_regular: var(--isp-dc-fremen);
  --ispui-button__sixth_background: transparent;
  --ispui-button__sixth_text_hover: var(--isp-dc-lapis-lazuli);
  --ispui-button__sixth_border_hover: var(--isp-dc-lapis-lazuli);
  --ispui-button__sixth_text_active: var(--isp-dc-fremen);
  --ispui-button__sixth_border_active: var(--isp-dc-fremen);
  --ispui-button__sixth_text_disabled: var(--isp-c-grey-weekdays);
  --ispui-button__sixth_border_disabled: var(--isp-c-inconspicuous);
  --ispui-button__sixth_text_disabled_hover: var(--isp-c-grey-weekdays);
  --ispui-button__sixth_border_disabled_hover: var(--isp-c-inconspicuous);
}

:root {
  --ispui-card__background-color: var(--isp-dc-monochrome);
  --ispui-card__border-radius: var(--isp-border-radius-main);
  --ispui-card__box-shadow: var(--isp-box-shadow);
}

:root {
  --ispui-checkbox__font-color: var(--isp-dc-highway-star);
  --ispui-checkbox__border-color: var(--isp-c-grey-weekdays);
  --ispui-checkbox__background-color: var(--isp-dc-monochrome);
  --ispui-checkbox__background-image-icon-empty: "";
  --ispui-checkbox__border-color-checked: var(--isp-c-blue-velvet);
  --ispui-checkbox__background-color-checked: var(--isp-dc-monochrome);
  --ispui-checkbox__background-image-icon-checked: var(
    --isp-checkbox-checked-image
  );
  --ispui-checkbox__font-color-disabled: var(--isp-c-grey-weekdays);
  --ispui-checkbox__border-color-disabled: var(--isp-dc-charlie-chaplin);
  --ispui-checkbox__background-color-disabled: var(--isp-dc-norilsk);
  --ispui-checkbox__background-image-icon-disabled: "";
  --ispui-checkbox__background-image-icon-checked-disabled: var(
    --isp-checkbox-checked-image-disabled
  );
  --ispui-checkbox__focus-border-color: #187abd4d;
}

:root {
  --ispui-close__dark-color: var(--isp-c-malewicz);
  --ispui-close__gray-color: var(--isp-c-grey-weekdays);
  --ispui-close__light-color: var(--isp-c-russian-north);
  --ispui-close__light-background-color_hover: var(--isp-c-malewicz);
  --ispui-close__light-background-color_active: transparent;
  --ispui-close__border-radius_focus: var(--isp-border-radius-main);
}

:root {
  --ispui-confirm__text-color: var(--isp-dc-highway-star);
  --ispui-confirm__container-bg-color: var(--isp-dc-cow);
  --isp-color__modal-border: var(--isp-dc-charlie-chaplin);
  --ispui-confirm__container-shadow: var(--isp-c-desolation-of-smaug);
  --ispui-confirm__overlay-background-color: var(--isp-c-desolation-of-smaug);
  --ispui-confirm__box-shadow: var(--isp-box-shadow);
}
@media (min-width: 300px) {
  :root {
    --ispui-confirm__width-small: 320px;
  }
}
@media (min-width: 360px) {
  :root {
    --ispui-confirm__width-small: 360px;
  }
}
@media (min-width: 390px) {
  :root {
    --ispui-confirm__width-small: 390px;
  }
}
@media (min-width: 420px) {
  :root {
    --ispui-confirm__width-small: 420px;
  }
}

.ispui-confirm__container {
  border: 1px solid var(--isp-dc-charlie-chaplin);
}
.ispui-confirm__container .ispui-confirm__header, .ispui-confirm__container .ispui-confirm__button-group {
  background-color: unset;
}

:root {
  --ispui-drawer-breadcrumbs__border-radius: var(--isp-border-radius-main);
  --ispui-drawer__background: var(--isp-dc-monochrome);
  --ispui-drawer_scrollbar-track-width: 5px;
  --ispui-drawer_scrollbar-track-border-radius: 10px;
  --ispui-drawer_scrollbar-track-color: var(--isp-c-inconspicuous);
  --ispui-drawer_scrollbar-thumb-color: var(--isp-c-grey-weekdays);
  --ispui-drawer_scrollbar-thumb-color_hover: var(--isp-c-blue-velvet);
  --ispui-drawer-close__background-color: var(--isp-dc-monochrome);
  --ispui-drawer-close__background-color_hover: var(--isp-dc-monochrome);
  --ispui-drawer-close__border-color: var(--isp-dc-charlie-chaplin);
}

:root {
  --ispui-dropdown-border: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-dropdown-bg-color: var(--isp-dc-cow);
  --ispui-dropdown-popup__container_box-shadow: var(--isp-box-shadow);
  --ispui-dropdown-min-width: 320px;
  --ispui-dropdown-min-height: 40px;
  --ispui-dropdown-scroll-background-color: var(--isp-c-inconspicuous);
  --ispui-dropdown-scroll-indicator-color: var(--isp-c-grey-weekdays);
  --ispui-dropdown-scroll-indicator-color_hover: var(--isp-c-blue-velvet);
  --ispui-dropdown__pointer-box-shadow: var(--isp-box-shadow);
  --ispui-close__gray-color: var(--isp-c-grey-weekdays);
  --ispui-close__gray-background-color_hover: var(--isp-dc-glass);
}

.ispui-dropdown__pointer {
  top: 0;
}

.ispui-dropdown-popup_direction_left .ispui-dropdown__pointer {
  transform: translate(-15%, 25%) rotateZ(90deg);
}

:root {
  --ispui-header__text-color: var(--isp-text-color-main);
  --ispui-header__text-invert-color: var(--isp-c-russian-north);
}

:root {
  --ispui-input__border-color: var(--isp-dc-charlie-chaplin);
  --ispui-input__border-color_active: var(--isp-dc-fremen);
  --ispui-input__text-color: var(--isp-dc-highway-star);
  --ispui-input__text-color_disabled: var(--isp-c-grey-weekdays);
  --ispui-input__label-color: var(--isp-c-grey-weekdays);
  --ispui-input__background-color_disabled: var(--isp-dc-mimic);
  --ispui-input__background-color: var(--isp-dc-monochrome);
  --ispui-input__accent-color: var(--isp-dc-bright-emotions);
  --ispui-input__active-color: var(--isp-dc-fremen);
  --ispui-input__prefix-color: var(--isp-c-grey-weekdays);
  --ispui-input__prefix-color_disabled: var(--isp-c-grey-weekdays);
  --ispui-input_regular__clear-button-background-color: transparent;
  --ispui-input_regular__clear-button-background-color_hover: var(
    --isp-dc-glass
  );
  --ispui-input_regular__clear-button-background-color_active: var(
    --isp-dc-glass
  );
  --ispui-input_regular__clear-button-cross-color: var(--isp-dc-highway-star);
  --ispui-input_regular__clear-button-cross-color_hover: var(
    --isp-dc-highway-star
  );
  --ispui-input_regular__clear-button-cross-color_active: var(
    --isp-dc-highway-star
  );
  --ispui-input_invalid__clear-button-background-color: var(
    --isp-dc-bright-emotions
  );
  --ispui-input_invalid__clear-button-background-color_hover: var(
    ---isp-dc-bright-emotions
  );
  --ispui-input_invalid__clear-button-background-color_active: var(
    --isp-c-mabel-pines
  );
  --ispui-input_invalid__clear-button-cross-color: var(--isp-dc-highway-star);
  --ispui-input_invalid__clear-button-cross-color_hover: var(
    --isp-dc-highway-star
  );
  --ispui-input_invalid__clear-button-cross-color_active: var(
    --isp-dc-highway-star
  );
  --ispui-input_disabled__clear-button-background-color: var(
    --isp-c-grey-weekdays
  );
  --ispui-input_disabled__clear-button-background-color_hover: var(
    --isp-c-grey-weekdays
  );
  --ispui-input_disabled__clear-button-background-color_active: var(
    --isp-c-grey-weekdays
  );
  --ispui-input_disabled__clear-button-cross-color: var(--isp-dc-highway-star);
  --ispui-input_disabled__clear-button-cross-color_hover: var(
    --isp-dc-highway-star
  );
  --ispui-input_disabled__clear-button-cross-color_active: var(
    --isp-dc-highway-star
  );
  --ispui-input_focused__clear-button-background-color: transparent;
  --ispui-input_focused__clear-button-background-color_hover: var(
    --isp-dc-glass
  );
  --ispui-input_focused__clear-button-background-color_active: var(
    --isp-dc-glass
  );
  --ispui-input_focused__clear-button-cross-color: var(--isp-dc-highway-star);
  --ispui-input_focused__clear-button-cross-color_hover: var(
    --isp-dc-highway-star
  );
  --ispui-input_focused__clear-button-cross-color_active: var(
    --isp-dc-highway-star
  );
  --ispui-input-validation-icon-background-color_valid: var(
    --isp-c-grasshopper
  );
  --ispui-input-validation-icon-stroke-color_valid: var(--isp-dc-monochrome);
  --ispui-input-validation-icon-background-color_invalid: var(
    --isp-dc-bright-emotions
  );
  --ispui-input-validation-icon-stroke-color_invalid: var(--isp-dc-monochrome);
  --ispui-scrollbar-width: 5px;
  --ispui-scrollbar-border-radius: 10px;
  --ispui-scrollbar-thumb-color: var(--isp-c-grey-weekdays);
  --ispui-scrollbar-thumb-color_hover: var(--isp-c-blue-velvet);
  --ispui-scrollbar-background-color: var(--isp-dc-charlie-chaplin);
  --ispui-password-icon-color: var(--isp-c-grey-weekdays);
  --ispui-input__label-left-offset: 15px;
}

:root {
  --ispui-link__color-additional: var(--isp-c-grey-weekdays);
  --ispui-color__additional-text: var(--isp-c-grey-weekdays);
  --ispui-link__color_fail: var(--isp-dc-bright-emotions);
  --ispui-link__color_fail_hover: var(---isp-dc-bright-emotions);
  --ispui-link__color_fail_active: var(--isp-c-mabel-pines);
  --ispui-link__color_inverse: var(--isp-c-happy-bob);
  --ispui-link__color_inverse_hover: var(--isp-c-happy-bob);
  --ispui-link__color_inverse_active: var(--isp-c-c-3po);
  --ispui-link__color_regular: var(--isp-dc-fremen);
  --ispui-link__color_hover: var(--isp-dc-lapis-lazuli);
  --ispui-link__color_active: var(--isp-dc-fremen);
  --ispui-link__color_inactive: var(--isp-c-grey-weekdays);
  --ispui-link__color_success: var(--isp-c-grasshopper);
  --ispui-link__color_success_hover: var(--isp-c-shire);
  --ispui-link__color_success_active: var(--isp-c-grasshopper);
}

:root {
  --ispui-notification__b-radius: var(--isp-border-radius-main);
  --ispui-notification__bg-color_info: var(--isp-dc-cow);
  --ispui-notification__border-color_info: var(--isp-dc-charlie-chaplin);
  --ispui-notification__icon-img_info: var(--ispui-banner__icon-img_info);
  --ispui-notification__bg-color_success: var(--isp-dc-cow);
  --ispui-notification__border-color_success: var(--isp-dc-charlie-chaplin);
  --ispui-notification__icon-img_success: var(--ispui-banner__icon-img_success);
  --ispui-notification__bg-color_warning: var(--isp-dc-new-vegas);
  --ispui-notification__border-color_warning: var(--isp-dc-heliodor);
  --ispui-notification__icon-img_warning: var(--ispui-banner__icon-img_warning);
  --ispui-notification__bg-color_danger: var(
    --isp-dc-dirty-deeds-done-dirt-cheap
  );
  --ispui-notification__border-color_danger: var(--isp-dc-rhodochrosite);
  --ispui-notification__icon-img_danger: var(--ispui-banner__icon-img_danger);
  --ispui-notification__close-button-bg-color_hover: var(--isp-dc-zebra);
  --ispui-notification__close-button-bg-color_active: var(--isp-dc-zebra);
}
:root ispui-notification[status=warning],
:root ispui-notification[status=danger] {
  --ispui-notification__close-button-bg-color_hover: var(--isp-dc-glass);
  --ispui-notification__close-button-bg-color_active: var(--isp-dc-glass);
}

:root {
  --ispui-preloader-round__background-color: var(--isp-dc-glass);
  --ispui-preloader-round__color: var(--isp-c-mabel-pines);
}

:root {
  --ispui-progress-bar__custom-color: linear-gradient(
    270deg,
    var(--isp-dc-mass-relay) 0%,
    var(--isp-c-blue-velvet) 50%,
    var(--isp-dc-mass-relay) 100%
  );
  --ispui-progress-bar__rainbow-animation-duration: 5s;
  --ispui-progress-bar__border-radius: 0;
  --ispui-progress-bar__height: 7px;
}

:root {
  --ispui-radio-circle-checked-color: var(--isp-c-blue-velvet);
  --ispui-radio-circle-unchecked-color: var(--isp-c-grey-weekdays);
  --ispui-radio-label-color: var(--isp-c-malewicz);
  --ispui-radio--label-color_disabled: var(--isp-c-grey-weekdays);
  --ispui-radio-circle-background-color_disabled: var(--isp-c-jon-snow);
  --ispui-radio-outline-color: unset;
}

:root {
  --ispui-select-button__color: var(--isp-dc-highway-star);
  --ispui-select-button__arrow-border: 1px solid var(--isp-dc-highway-star);
  --ispui-select-button__border-focus: 1px solid var(--isp-dc-fremen);
  --ispui-select-button-search__border: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-select-button-search__arrow-border: 1px solid
    var(--isp-dc-highway-star);
  --ispui-select-button-search__border-focus: 1px solid var(--isp-dc-fremen);
  --ispui-select-search__border: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-select-button__border: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-select-button-legend__border: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-select-button-legend__color: var(--isp-dc-highway-star);
  --ispui-select-button-legend__legend-color: var(--isp-c-lovely-puddle);
  --ispui-select-button-legend__border-color-focus: var(--isp-text-color-link);
  --ispui-select-button-legend__legend-color-focus: var(--isp-text-color-link);
  --ispui-select-button-legend__arrow-border: 1px solid
    var(--isp-dc-highway-star);
  --ispui-select-button-legend__br: var(--isp-border-radius-main);
  --ispui-select-button-legend__asterisk-color: var(--isp-dc-bright-emotions);
  --ispui-select-button-line__color: var(--isp-dc-highway-star);
  --ispui-select-button-line__arrow-border: 1px solid var(--isp-dc-highway-star);
  --ispui-select-button-line__focus-color: var(--isp-dc-fremen);
  --ispui-select-button-chip__color: var(--isp-dc-fremen);
  --ispui-select-button-chipt__border: 1px solid var(--isp-dc-fremen);
  --ispui-select-button-chip__arrow-border: 1px solid var(--isp-dc-fremen);
  --ispui-select-button-chipt__border-focus: 2px solid var(--isp-dc-fremen);
  --ispui-select-list__item-color: var(--isp-dc-highway-star);
  --ispui-select-list__item-highlight-color: var(--isp-dc-cult);
  --ispui-select-list__disabled-item-color: var(--isp-dc-charlie-chaplin);
  --ispui-select-list__max-height: 350px;
  --ispui-select-list__item-br: var(--isp-border-radius-main);
  --ispui-select-list__item-checkbox-pl: 15px;
  --ispui-select-list__item-search-match-bg: var(--isp-c-happy-bob);
  --ispui-select-button-search__color: var(--isp-dc-highway-star);
  --ispui-button__fourth_text_regular: var(--isp-dc-fremen);
  --ispui-button__fourth_text_hover: var(--isp-dc-lapis-lazuli);
  --ispui-button__fourth_text_active: var(--isp-dc-fremen);
  --ispui-select-list__scroll-width: 5px;
  --ispui-select-list__scroll-border-radius: 10px;
  --ispui-select-list__scroll-track-color: var(--isp-dc-charlie-chaplin);
  --ispui-select-list__scroll-bar-color: var(--isp-c-grey-weekdays);
  --ispui-select-list__scroll-bar-color-hover: var(--isp-c-blue-velvet);
  --ispui-select-search__padding: 10px;
  --ispui-select-search__margin: 0;
  --ispui-select-list__margin: 0 0 0 10px;
  --ispui-select-list__padding: 0 10px 0 0;
  --ispui-select-list__min-width: calc(400px - 20px);
  --ispui-select-list__max-width: calc(400px - 20px);
  --ispui-select-search__br: var(--isp-border-radius-main);
}
:root .ispui-select-search_input {
  background: none;
}
:root .ispui-select-list__item-search-match {
  color: var(--isp-c-malewicz);
}

:root {
  --ispui-slider__unlimited-icon-color: var(--isp-c-malewicz);
}

:root {
  --ispiu-swtich__button-border: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-switcher__br: var(--isp-border-radius-main);
  --ispiu-swtich__button-bg: var(--isp-dc-monochrome);
  --ispiu-swtich__button-bg-hover: transparent;
  --ispiu-swtich__button-bg_selected: var(--isp-dc-made-in-heaven);
  --ispiu-swtich__button-bg_selected-hover: var(--isp-dc-made-in-heaven);
}

:root {
  --ispui-table-footer__background: var(--isp-dc-monochrome);
  --ispui-table-footer__color: var(--isp-text-color-main);
  --ispui-table__theme-light-color: var(--isp-c-grey-weekdays);
  --ispui-table__theme-light-bg: var(--isp-dc-monochrome);
  --ispui-table__theme-neutral-color: var(--isp-c-grey-weekdays);
  --ispui-table__theme-neutral-bg: var(--isp-c-russian-north);
  --ispui-table__theme-dark-color: var(--isp-c-russian-north);
  --ispui-table__theme-dark-bg: var(--isp-c-malewicz);
  --ispui-table__border: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-table__background: var(--isp-dc-monochrome);
  --ispui-table-checkbox__border: 1px solid var(--isp-c-grey-weekdays);
  --ispui-table-resizer__lever-color: var(--isp-dc-charlie-chaplin);
  --ispui-table-resizer__lever_alert-color: var(--isp-dc-bright-emotions);
  --ispui-table-resizer__lever_info-color: var(--isp-dc-fremen);
  --ispui-table-resizer__splitter-color: var(--isp-dc-charlie-chaplin);
  --ispui-table-resizer__splitter_alert-color: var(--isp-dc-bright-emotions);
  --ispui-table-resizer__splitter_info-color: var(--isp-dc-fremen);
  --ispui-table-kebab__hover-bg: var(--isp-c-wedding-dress);
  --ispui-table-gap-container__gap-bg: var(--isp-c-jon-snow);
  --ispui-table-checkbox__minus-border-color: var(--isp-c-blue-velvet);
  --ispui-table-checkbox__minus-bg-color: var(--isp-c-blue-velvet);
  --ispui-table-checkbox__checked-border-color: var(--isp-c-blue-velvet);
  --ispui-table-card__bg: var(--isp-c-jon-snow);
  --ispui-table-card__box-shadow: var(--isp-box-shadow);
  --ispui-table-card__color: var(--isp-c-malewicz);
  --ispui-table-card__marker-bg: var(--isp-dc-fremen);
  --ispui-table-card__border-bottom: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-table__text-color: var(--isp-c-malewicz);
  --ispui-table__text-color-disabled: var(--isp-c-grey-weekdays);
  --ispui-table__additional-text-color: var(--isp-c-grey-weekdays);
  --ispui-table__action-text-color: var(--isp-dc-fremen);
  --ispui-table__row-disabled-color: var(--isp-c-grey-weekdays);
  --ispui-table__row-disabled-bg: var(--isp-c-russian-north);
  --ispui-table__action-text-error-color: var(--isp-dc-bright-emotions);
  --ispui-table__chosen-row-marker-border: 4px solid var(--isp-c-blue-velvet);
  --ispui-table-pagination__input-border: 1px solid
    var(--isp-dc-charlie-chaplin);
  --ispui-table-pagination__size-var-color: var(--isp-dc-highway-star);
  --ispui-table-pagination__size-var-hover-bg: var(--isp-dc-cult);
}
:root .ispui-table-pagination__input {
  background-color: var(--isp-dc-monochrome);
}
:root .ispui-table-pagination__input:focus-visible {
  outline: 1px solid var(--isp-dc-fremen);
}
:root .ispui-table-checkbox_disabled {
  background-color: var(--isp-c-russian-north);
}
:root .ispui-table-gap-container__gap_no-data::after {
  color: var(--isp-c-malewicz);
}
:root .ispui-table__row {
  --ispui-table__row-bg: var(--isp-dc-former);
  --ispui-table__row-hover-bg: var(--isp-dc-the-institute);
  --ispui-table__row-chosen-bg: var(--isp-dc-mass-relay);
}
:root .ispui-table__row:nth-child(2n) {
  --ispui-table__row-bg: var(--isp-dc-monochrome);
  --ispui-table__row-chosen-bg: var(--isp-dc-made-in-heaven);
}

:root {
  --ispui-text__default_color: var(--isp-dc-highway-star);
  --ispui-text__warning_color: var(--isp-dc-bright-emotions);
  --ispui-text__success_color: var(--isp-c-grasshopper);
  --ispui-text__hint_color: var(--isp-c-grey-weekdays);
}

:root {
  --ispui-toast__border-radius: var(--isp-border-radius-main);
}

:root {
  --ispui-toggle-background-color: var(--isp-dc-monochrome);
  --ispui-toggle-background-color_checked: var(--isp-c-grasshopper);
  --ispui-toggle-border-color_checked: var(--isp-c-grasshopper);
  --ispui-toggle-background-color_theme: var(--isp-c-jon-snow);
  --ispui-toggle-switch-color_checked: var(--isp-c-jon-snow);
  --ispui-toggle-switch-border-color_checked: var(--isp-c-jon-snow);
  --ispui-toggle-background-color_disabled: var(--isp-c-jon-snow);
  --ispui-toggle-border-color: var(--isp-c-grey-weekdays);
  --ispui-toggle-switch-color: var(--isp-c-grey-weekdays);
  --ispui-toggle-switch-border-color: var(--isp-c-grey-weekdays);
  --ispui-toggle-border-color_disabled: var(--isp-c-grey-weekdays);
  --ispui-toggle-switch-color_disabled: var(--isp-c-grey-weekdays);
  --ispui-toggle-switch-border-color_disabled: var(--isp-c-grey-weekdays);
  --ispui-toggle-background-color_checked-disabled: var(--isp-c-grey-weekdays);
  --ispui-toggle-border-color_checked-disabled: var(--isp-c-grey-weekdays);
  --ispui-toggle-switch-color_checked-disabled: var(--isp-c-jon-snow);
  --ispui-toggle-switch-border-color_checked-disabled: var(--isp-c-jon-snow);
  --ispui-toggle__outline-color: unset;
  --ispui-toggle__label-color: var(--isp-c-malewicz);
  --ispui-toggle-control__border-radius: var(--isp-border-radius-main);
}

:root {
  --ispui-tooltip-font-main-color: var(--isp-c-jon-snow);
  --ispui-tooltip-background: var(--isp-dc-brotherhood-of-steel);
  --ispui-tooltip-ellipsis__text-overflow: clip;
  --ispui-tooltip-box-shadow: var(--isp-box-shadow);
}
:root .ispui-tooltip a {
  text-decoration: underline;
  color: var(--isp-c-jon-snow);
  font-size: 12px;
}
@media (min-width: 960px) {
  :root {
    --ispui-tooltip-ellipsis__text-overflow: ellipsis;
  }
}

:root {
  --ispui-validation-error-background-color: var(--isp-dc-new-vegas);
  --ispui-validation-error-box-shadow-color: var(--isp-c-desolation-of-smaug);
  --ispui-validation-error-color: var(--isp-dc-highway-star);
  --ispui-validation-error__close-background-color: var(--isp-c-grey-weekdays);
  --ispui-validation-error__box-shadow: var(--isp-box-shadow);
  --ispui-validation-error-triangle__box-shadow: var(--isp-box-shadow);
  --ispui-validation-error__border-radius: var(--isp-border-radius-main);
  --ispui-validation-error__close-border-radius: var(--isp-border-radius-main);
}

:root {
  --isp-link__color_regular: var(--isp-dc-fremen);
  --isp-link__color_hover: var(--isp-dc-lapis-lazuli);
  --isp-link__color_active: var(--isp-dc-fremen);
  --ngispui-checkbox__border-color: var(--isp-c-grey-weekdays);
  --ngispui-checkbox__border-color-checked: var(--isp-c-blue-velvet);
  --ngispui-checkbox__icon-check: var(--isp-checkbox-checked-image);
  --ngispui-checkbox__border-color-disabled: var(--isp-c-grey-weekdays);
  --ngispui-checkbox__icon-check-disabled: var(
    --isp-checkbox-checked-image-disabled
  );
  --ngispui-notification-group__top: auto;
  --ngispui-notification-group__bottom: 20px;
  --ngispui-file-name_color: var(--isp-dc-highway-star);
}

.ngispui-file span.ngispui-file__remove {
  width: 15px;
  height: 15px;
  margin-top: 2px;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 6c.28 0 .5.22.5.5v6a.5.5 0 0 1-1 0v-6c0-.28.22-.5.5-.5ZM8 6.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0v-6ZM11 6.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0v-6Z' fill='%23999'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.09 2A1.5 1.5 0 0 1 6.5 0h2a1.5 1.5 0 0 1 1.41 2h1.65A2.44 2.44 0 0 1 14 4.44c0 .3-.25.56-.56.56H13v7.5a2.5 2.5 0 0 1-2.5 2.5h-6A2.5 2.5 0 0 1 2 12.5V5h-.44A.56.56 0 0 1 1 4.44 2.44 2.44 0 0 1 3.44 2h1.65ZM6 1.5c0-.28.22-.5.5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5ZM3.44 3c-.65 0-1.19.42-1.37 1h10.86c-.18-.58-.72-1-1.37-1H3.44ZM3 5h9v7.5c0 .83-.67 1.5-1.5 1.5h-6A1.5 1.5 0 0 1 3 12.5V5Z' fill='%23999'/%3E%3C/svg%3E");
}

body .tox .tox-dialog {
  max-width: 600px;
}

* {
  box-sizing: border-box;
}

body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  color: var(--isp-text-color-main);
  background-color: var(--isp-dc-monochrome);
  font-size: 14px;
}
@media (min-width: 640px) {
  body {
    --ispui-dropdown-min-width: 150px;
  }
}
@media (max-width: 960px) {
  body {
    overflow-x: auto;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
  color: var(--isp-dc-fremen);
  font-family: var(--ispui-link__font-family, Ubuntu, sans-serif);
  font-size: var(--ispui-font-size-regular, 14px);
}
a:hover {
  text-decoration: underline;
  color: var(--isp-dc-lapis-lazuli);
}
a:active {
  text-decoration: underline;
  color: var(--isp-dc-fremen);
}

.ispui-scrollbar_gray {
  /** Scrollbar styling for Firefox */
  /** The first applies to the thumb of the scrollbar, the second to the track. */
  scrollbar-color: var(--isp-c-grey-weekdays) var(--isp-dc-charlie-chaplin);
  scrollbar-width: thin;
  /** Scrollbar styling for Chrome */
}
.ispui-scrollbar_gray::-webkit-scrollbar, .ispui-scrollbar_gray::-webkit-scrollbar-track, .ispui-scrollbar_gray::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 10px;
}
.ispui-scrollbar_gray::-webkit-scrollbar, .ispui-scrollbar_gray::-webkit-scrollbar-track {
  background-color: var(--isp-dc-charlie-chaplin);
}
.ispui-scrollbar_gray::-webkit-scrollbar-thumb {
  background-color: var(--isp-c-grey-weekdays);
}
.ispui-scrollbar_gray::-webkit-scrollbar-thumb:hover {
  background-color: var(--isp-c-blue-velvet);
}
.ispui-scrollbar_gray::-webkit-scrollbar-button {
  display: none;
}

.isp-toolbar-button-hint a {
  display: block;
  width: fit-content;
  margin-top: 5px;
  text-decoration: none;
  color: var(--isp-c-happy-bob);
}

.isp-usefullink a {
  display: block;
  margin-bottom: 11px;
  text-decoration: none;
  color: var(--isp-dc-fremen);
}
.isp-usefullink a:hover {
  color: var(--isp-dc-lapis-lazuli);
}
.isp-usefullink a:active {
  color: var(--isp-dc-fremen);
}

/** Styles for user menu dropdown content */
ispui-dropdown-popup {
  --ngispui-vmenu-item__icon-size: 20px;
  --ngispui-vmenu-highlight: var(--isp-c-jon-snow);
  /** for context menu with one  */
  --ispui-dropdown-popup__container_min-height: 55px;
}
ispui-dropdown-popup .ngispui-vmenu-item__icon {
  line-height: 0;
}

.isp-list-table-settings-popup * {
  /** Scrollbar styling for Firefox */
  /** The first applies to the thumb of the scrollbar, the second to the track. */
  scrollbar-color: var(--isp-c-grey-weekdays) var(--isp-dc-charlie-chaplin);
  scrollbar-width: thin;
  /** Scrollbar styling for Chrome */
}
.isp-list-table-settings-popup *::-webkit-scrollbar, .isp-list-table-settings-popup *::-webkit-scrollbar-track, .isp-list-table-settings-popup *::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 10px;
}
.isp-list-table-settings-popup *::-webkit-scrollbar, .isp-list-table-settings-popup *::-webkit-scrollbar-track {
  background-color: var(--isp-dc-charlie-chaplin);
}
.isp-list-table-settings-popup *::-webkit-scrollbar-thumb {
  background-color: var(--isp-c-grey-weekdays);
}
.isp-list-table-settings-popup *::-webkit-scrollbar-thumb:hover {
  background-color: var(--isp-c-blue-velvet);
}
.isp-list-table-settings-popup *::-webkit-scrollbar-button {
  display: none;
}

.isp-dynamic-form-select-dropdown-popup {
  --ispui-dropdown-content-padding: 10px 0 !important;
  max-height: calc(100vh - 40px) !important;
}

.isp-top-bar-menu-dropdown {
  --ispui-dropdown-border-radius: 5px;
  --ispui-dropdown-content-padding: 10px;
  --ispui-dropdown-content-pr: 0;
  --ispui-dropdown-min-width: 200px;
  --ispui-dropdown-content-display: block;
  --ngispui-vmenu-option__gap: 0;
  --ngispui-vmenu-item__icon-gap: 10px;
  --ngispui-vmenu-highlight: var(--isp-text-color-main);
  --ngispui-vmenu-option__color: var(--isp-text-color-main);
}

.isp-toolbar-dropdown__popup {
  --ispui-dropdown-content-pr: 0;
  --ispui-dropdown-min-width: 200px;
  --ispui-dropdown-content-padding: 10px;
}
.isp-toolbar-dropdown__popup .ispui-dropdown-content__wrapper {
  width: 100%;
}

.isp-list-elem-context-menu-dropdown {
  --ispui-dropdown-border-radius: 5px;
  --ispui-dropdown-min-width: 245px;
  --ispui-dropdown-max-width: 300px;
  --ispui-dropdown-content-display: block;
}

.isp-list-filter-dropdown-popup {
  --ispui-dropdown-border-radius: 5px;
  --ispui-dropdown-footer-padding: 10px 20px 25px 20px;
  --ispui-dropdown-max-width: 622px;
  transform: translateX(30px);
}
.isp-list-filter-dropdown-popup .ispui-dropdown-content__wrapper {
  --ispui-dropdown-content-pr: 15px;
  overflow: overlay;
  padding-top: 10px;
}

.isp-saved-messages-dropdown__popup {
  --ispui-dropdown-max-width: 600px;
  --ispui-dropdown-content-max-height: 335px;
}

.isp-session-tip__popup {
  --ispui-dropdown-bg-color: var(--isp-dc-made-in-heaven);
  --ispui-dropdown-max-width: 230px;
  --ispui-dropdown-min-width: 230px;
  --ispui-dropdown-content-padding: 10px 40px 10px 10px;
  --ispui-dropdown-content-pr: 0;
  --ispui-dropdown-border: 1px solid var(--isp-dc-the-institute);
  --ispui-dropdown-border-radius: 5px;
  --ispui-dropdown-popup__container_min-height: 10%;
  --ispui-close__size: 15px;
  --ispui-dropdown-close-top: 7px;
  --ispui-dropdown-close-right: 7px;
  --ispui-dropdown__pointer-width: 8px;
  --ispui-dropdown__pointer-height: 8px;
  --ispui-dropdown__pointer-wrapper-width: 18px;
  --ispui-dropdown__pointer-wrapper-height: 12px;
  color: var(--isp-text-color-main);
}
.isp-session-tip__popup .ispui-dropdown__pointer::before {
  border: 1px solid var(--isp-dc-the-institute);
}

.isp-useful-links__popup .ispui-dropdown-content__wrapper {
  position: relative;
  overflow-y: hidden;
  min-width: 200px;
  padding: 44px 0 0;
}
.isp-useful-links__popup .ispui-dropdown-content {
  max-width: 330px;
  padding: 0 0 5px;
}
.isp-useful-links__popup .ispui-dropdown-content .isp-usefullinks__title {
  position: absolute;
  top: 0;
  width: 100%;
  height: 44px;
  padding: 15px;
  border-bottom: 1px solid var(--isp-dc-charlie-chaplin);
  font-weight: bold;
}
.isp-useful-links__popup .ispui-dropdown-content .isp-usefullinks {
  overflow: auto;
  height: 100%;
  max-height: 321px;
  padding: 15px 15px 0;
  /** Scrollbar styling for Firefox */
  /** The first applies to the thumb of the scrollbar, the second to the track. */
  scrollbar-color: var(--isp-c-grey-weekdays) var(--isp-dc-charlie-chaplin);
  scrollbar-width: thin;
  /** Scrollbar styling for Chrome */
}
.isp-useful-links__popup .ispui-dropdown-content .isp-usefullinks::-webkit-scrollbar, .isp-useful-links__popup .ispui-dropdown-content .isp-usefullinks::-webkit-scrollbar-track, .isp-useful-links__popup .ispui-dropdown-content .isp-usefullinks::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 10px;
}
.isp-useful-links__popup .ispui-dropdown-content .isp-usefullinks::-webkit-scrollbar, .isp-useful-links__popup .ispui-dropdown-content .isp-usefullinks::-webkit-scrollbar-track {
  background-color: var(--isp-dc-charlie-chaplin);
}
.isp-useful-links__popup .ispui-dropdown-content .isp-usefullinks::-webkit-scrollbar-thumb {
  background-color: var(--isp-c-grey-weekdays);
}
.isp-useful-links__popup .ispui-dropdown-content .isp-usefullinks::-webkit-scrollbar-thumb:hover {
  background-color: var(--isp-c-blue-velvet);
}
.isp-useful-links__popup .ispui-dropdown-content .isp-usefullinks::-webkit-scrollbar-button {
  display: none;
}
.isp-useful-links__popup .ispui-dropdown-content a {
  display: block;
  margin-bottom: 11px;
  text-decoration: none;
  color: var(--isp-dc-fremen);
}
.isp-useful-links__popup .ispui-dropdown-content a:hover {
  text-decoration: underline;
  color: var(--isp-c-deep);
}
.isp-useful-links__popup .ispui-dropdown-content a:active {
  color: var(--isp-dc-fremen);
}

ispui-dropdown-popup.menu__dropdown_popup,
ispui-dropdown-popup.menu__group-dropdown_popup {
  --ispui-dropdown-bg-color: var(--isp-dc-cow);
  --ispui-dropdown-min-height: 45px;
  --ispui-dropdown-min-width: 25px;
  --ispui-dropdown-content-pr: 0;
  --ispui-dropdown-content-min-height: 45px;
}
ispui-dropdown-popup.menu__dropdown_popup .ispui-dropdown-popup__container,
ispui-dropdown-popup.menu__group-dropdown_popup .ispui-dropdown-popup__container {
  min-height: 45px;
}
ispui-dropdown-popup.menu__dropdown_popup .menu-node__side-marked,
ispui-dropdown-popup.menu__group-dropdown_popup .menu-node__side-marked {
  color: var(--isp-dc-fremen);
}
ispui-dropdown-popup.menu__dropdown_popup .menu-node__side-marked::before,
ispui-dropdown-popup.menu__group-dropdown_popup .menu-node__side-marked::before {
  content: "";
  position: absolute;
  left: 0;
  width: 4px;
  height: 25px;
  margin-top: -5px;
  background: var(--isp-c-blue-velvet);
}
ispui-dropdown-popup.menu__dropdown_popup isp-menu-item.menu__node-msg,
ispui-dropdown-popup.menu__group-dropdown_popup isp-menu-item.menu__node-msg {
  overflow: hidden;
  margin-left: 0;
}
ispui-dropdown-popup.menu__dropdown_popup isp-menu-item.menu__node-msg .menu-item__item-icon,
ispui-dropdown-popup.menu__group-dropdown_popup isp-menu-item.menu__node-msg .menu-item__item-icon {
  display: inline-block;
  margin-left: 10px;
}
ispui-dropdown-popup.menu__dropdown_popup .menu-tab-icon,
ispui-dropdown-popup.menu__group-dropdown_popup .menu-tab-icon {
  --isp-icons-fill-color: transparent;
  --isp-icons-main-color: var(--isp-c-grey-weekdays);
}
ispui-dropdown-popup.menu__dropdown_popup .menu-tab-icon:hover, ispui-dropdown-popup.menu__dropdown_popup .menu-tab-icon:active,
ispui-dropdown-popup.menu__group-dropdown_popup .menu-tab-icon:hover,
ispui-dropdown-popup.menu__group-dropdown_popup .menu-tab-icon:active {
  --isp-icons-main-color: var(--isp-dc-fremen);
}

ispui-dropdown-popup.menu__dropdown_popup .ispui-dropdown-content {
  --ispui-dropdown-content-padding: 14px 15px;
}

ispui-dropdown-popup.menu__group-dropdown_popup .ispui-dropdown-popup__container {
  min-height: 40px;
}
ispui-dropdown-popup.menu__group-dropdown_popup .ispui-dropdown-content__wrapper {
  overflow-y: hidden;
}
ispui-dropdown-popup.menu__group-dropdown_popup .ispui-dropdown-content {
  --ispui-dropdown-content-padding: 10px 15px;
  min-height: 40px;
}
ispui-dropdown-popup.menu__group-dropdown_popup .menu-item__item-icon {
  display: inline-block;
  margin-left: 10px;
  opacity: 0;
}
ispui-dropdown-popup.menu__group-dropdown_popup isp-menu-item:hover .menu-item__item-icon {
  opacity: 1;
}
ispui-dropdown-popup.menu__group-dropdown_popup .menu__dropdown-subnode {
  position: static;
}
ispui-dropdown-popup.menu__group-dropdown_popup .menu__subnode-item_side-marked::before {
  content: "";
  position: absolute;
  left: 0;
  width: 4px;
  height: 25px;
  margin-top: -5px;
  background: var(--isp-c-blue-velvet);
}
ispui-dropdown-popup.menu__group-dropdown_popup .menu__subnode-item:first-of-type {
  margin-top: 2px;
}
ispui-dropdown-popup.menu__group-dropdown_popup .menu__subnode-item:last-of-type {
  margin-bottom: 2px;
}

.isp-list-edit-cell-dropdown-popup {
  --ispui-dropdown-content-pr: 0;
  --ispui-dropdown-content-padding: 0;
  --ispui-dropdown-content-min-height: 30px;
  --ispui-dropdown-popup__container_min-height: 30px;
  --ispui-dropdown-popup__container_box-shadow: none;
  --ispui-dropdown-border: none;
  --ispui-dropdown-border-radius: 5px;
  --ispui-dropdown-bg-color: transparent;
}
.isp-list-edit-cell-dropdown-popup .ispui-dropdown-content__wrapper {
  width: 100%;
}

.isp-list-column-filter-dropdown-popup {
  --ispui-dropdown-min-width: 200px;
  --ispui-dropdown-max-width: 200px;
  --ispui-dropdown-content-pr: 0;
  --ispui-dropdown-content-padding: 0;
  --ispui-dropdown-border-radius: 5px;
}

.isp-color-scheme-dropdown {
  --ispui-dropdown-border-radius: 5px;
  --ispui-dropdown-content-padding: 10px;
  --ispui-dropdown-content-pr: 0;
  --ispui-dropdown-min-width: 150px;
  --ispui-dropdown-content-display: block;
}

/**
 * @WARN experimental! Used to hide not hydrated elements, until they will be hydrated
 */
.hidden-hydrated:not(.hydrated) {
  display: none;
}

ispui-notification-group.main-notification-group {
  --ispui-notification__border: var(--isp-dc-charlie-chaplin) 1px solid;
  --ispui-notification__stack-top_top-stroke: var(--isp-dc-charlie-chaplin);
  --ispui-notification__stack-top_bottom-stroke: var(--isp-dc-charlie-chaplin);
  --ispui-notification__stack-top_top-bg: var(--isp-dc-cow);
  --ispui-notification__stack-top_bottom-bg: var(--isp-dc-cow);
  --ispui-notification__content_padding: 18px 20px 20px;
  position: fixed;
  z-index: 996;
  right: 0;
  bottom: 0;
  box-sizing: content-box;
}
ispui-notification-group.main-notification-group ispui-notification {
  --ispui-notification__box-shadow: var(--isp-box-shadow);
  box-sizing: content-box;
  width: 330px;
}

.table-settings-extended-button {
  display: inline-block;
  margin: 18px 0 13px 20px;
  padding: 0;
  cursor: pointer;
  color: var(--isp-dc-fremen);
  border: none;
  background: none;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
}
.table-settings-extended-button:hover {
  color: var(--isp-dc-lapis-lazuli);
}
.table-settings-extended-button:active {
  color: var(--isp-dc-fremen);
}

.column-filter-apply {
  margin-top: 5px;
  border-top: 1px solid var(--isp-dc-charlie-chaplin);
}

.column-filter-apply__button {
  display: inline-block;
  margin: 13px 0 8px 15px;
  padding: 0;
  cursor: pointer;
  color: var(--isp-dc-fremen);
  border: none;
  background: none;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
}
.column-filter-apply__button:hover {
  color: var(--isp-dc-lapis-lazuli);
}
.column-filter-apply__button:active {
  color: var(--isp-dc-fremen);
}