.isp-social__delimiter {
  position: relative;
  display: flex;
  justify-content: center;

  margin-bottom: 25px;
}

.isp-social__delimiter:before {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  border: 1px solid var(--isp-c-inconspicuous);
}

.isp-social__delimiter__label {
  font: var(--isp-font-size_regular) Ubuntu, sans-serif;
  color: var(--isp-c-lovely-puddle);
  background-color: var(--isp-c-jon-snow);
  z-index: 1;
  padding: 0 10px;
}
