.isp-page__footer {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  row-gap: 20px;
}

.isp-page__content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 160px 0 140px;
  flex-shrink: 0;
  flex-grow: 1;
}

.isp-page__general-validation {
  margin-bottom: 30px;
  color: var(--isp-dc-bright-emotions);
  font-size: var(--isp-font-size_regular);
}

.isp-page__logo {
  display: flex;
  flex-shrink: 0;
  padding: 0 160px 0 140px;
  max-width: 100%;
  max-height: 200px;
}
.isp-page__logo .isp-logo-image {
  max-width: 100%;
  max-height: 100%;
}

.isp-page__old-browser-warning {
  margin-bottom: 30px;
  color: var(--isp-dc-bright-emotions);
  font-size: var(--isp-font-size_regular);
}

.isp-page__preloader {
  font-weight: normal;
}

.isp-page__docs {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
  justify-content: center;
  padding: 0 30px;
}
.isp-page__docs > .isp-docs_link {
  --ispui-link__color_regular: var(--isp-dc-highway-star);
  --ispui-link__color_hover: var(--isp-dc-highway-star);
  --ispui-link__color_active: var(--isp-dc-highway-star);
  text-align: center;
}

.isp-page__social {
  margin-top: 30px;
  width: 100%;
}

.isp-social__delimiter {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.isp-social__delimiter:before {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  border: 1px solid var(--isp-c-inconspicuous);
}

.isp-social__delimiter__label {
  font: var(--isp-font-size_regular) Ubuntu, sans-serif;
  color: var(--isp-c-lovely-puddle);
  background-color: var(--isp-c-jon-snow);
  z-index: 1;
  padding: 0 10px;
}

.isp-social__links {
  display: inline-flex;
  justify-content: center;
  column-gap: 24px;
  width: 100%;
}
.isp-social__links .isp-social__icon {
  width: 36px;
  height: 36px;
  display: block;
}
.isp-social__links .isp-social__link {
  display: block;
  text-decoration: none;
}

.isp-page__register {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  font-size: var(--isp-font-size_regular);
  color: var(--isp-c-lovely-puddle);
}

.isp-page__copyright {
  display: flex;
  justify-content: center;
}