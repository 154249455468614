:root {
  // ALL COLORS OF ISPUI-BUTTON v4.4.0
  --ispui-button__line-height: 33px;

  // general
  --ispui-button__icon-stroke: var(--isp-dc-fremen);
  --ispui-button__icon-fill: transparent;
  --ispui-button__icon-stroke_hover: var(--isp-c-deep);
  --ispui-button__icon-fill_hover: transparent;
  --ispui-button__icon-stroke_active: var(--isp-dc-fremen);
  --ispui-button__icon-fill_active: transparent;
  --ispui-button__border-radius: var(--isp-border-radius-main);
  --ispui-button__box-shadow: var(--isp-box-shadow);
  --ispui-button__inactive_box-shadow: var(--isp-box-shadow);

  // general disabled state
  --ispui-button__inactive_text_regular: var(--isp-c-grey-weekdays);
  --ispui-button__inactive_background_regular: var(--isp-dc-norilsk);
  --ispui-button__inactive-icon-stroke: var(--isp-c-grey-weekdays);
  --ispui-button__inactive-icon-fill: transparent;
  // hover
  --ispui-button__inactive_text_hover: var(--isp-c-grey-weekdays);
  --ispui-button__inactive_background_hover: var(--isp-dc-norilsk);
  --ispui-button__inactive-icon-stroke_hover: var(--isp-c-grey-weekdays);
  --ispui-button__inactive-icon-fill_hover: transparent;
  // active
  --ispui-button__inactive_text_active: var(--isp-c-grey-weekdays);
  --ispui-button__inactive_background_active: var(--isp-dc-norilsk);
  --ispui-button__inactive-icon-stroke_active: var(--isp-c-grey-weekdays);
  --ispui-button__inactive-icon-fill_active: transparent;

  // accent theme
  --ispui-button__accent_text_regular: var(--isp-c-malewicz);
  --ispui-button__accent_border_regular: transparent;
  --ispui-button__accent_background_regular: var(--isp-c-happy-bob);
  // hover
  --ispui-button__accent_text_hover: var(--isp-c-malewicz);
  --ispui-button__accent_border_hover: transparent;
  --ispui-button__accent_background_hover: var(--isp-c-honey-block);
  // active
  --ispui-button__accent_text_active: var(--isp-c-malewicz);
  --ispui-button__accent_border_active: transparent;
  --ispui-button__accent_background_active: var(--isp-c-happy-bob);

  // ispui-button third theme don't used in our interface!
  --ispui-button__third_text_regular: var(--isp-c-jon-snow);
  --ispui-button__third_border_regular: var(--isp-c-jon-snow);
  --ispui-button__third_background_regular: rgba(255, 255, 255, 0.5);
  // hover
  --ispui-button__third_text_hover: var(--isp-c-jon-snow);
  --ispui-button__third_border_hover: var(--isp-c-jon-snow);
  --ispui-button__third_background_hover: rgba(255, 255, 255, 0.5);
  // active
  --ispui-button__third_text_active: var(--isp-c-jon-snow);
  --ispui-button__third_border_active: var(--isp-c-jon-snow);
  --ispui-button__third_background_active: rgba(255, 255, 255, 0.5);

  // fourth theme. Overrided in form-button and ispui-select
  // --ispui-button__fourth_text_regular: var(--isp-c-russian-north);
  // --ispui-button__fourth_border_regular: transparent;
  // --ispui-button__fourth_background_regular: transparent;
  // // hover
  // --ispui-button__fourth_text_hover: var(--isp-c-happy-bob);
  // --ispui-button__fourth_border_hover: transparent;
  // --ispui-button__fourth_background_hover: transparent;
  // // active
  // --ispui-button__fourth_text_active: var(--isp-c-happy-bob);
  // --ispui-button__fourth_border_active: transparent;
  // --ispui-button__fourth_background_active: transparent;

  // primary theme
  --ispui-button__primary_text_regular: var(--isp-c-jon-snow);
  --ispui-button__primary_border_regular: transparent;
  --ispui-button__primary_background_regular: var(--isp-c-blue-velvet);
  // hover
  --ispui-button__primary_text_hover: var(--isp-c-jon-snow);
  --ispui-button__primary_border_hover: transparent;
  --ispui-button__primary_background_hover: var(--isp-c-deep);
  --ispui-button__primary_box-shadow_hover: var(--isp-box-shadow);
  // active
  --ispui-button__primary_text_active: var(--isp-c-jon-snow);
  --ispui-button__primary_border_active: transparent;
  --ispui-button__primary_background_active: var(--isp-c-blue-velvet);
  --ispui-button__primary_box-shadow_active: var(--isp-box-shadow);

  // sixth theme
  --ispui-button__sixth_border-radius: var(--isp-border-radius-main);
  --ispui-button__sixth_text_regular: var(--isp-dc-fremen);
  --ispui-button__sixth_border_regular: var(--isp-dc-fremen);
  --ispui-button__sixth_background: transparent;
  // hover
  --ispui-button__sixth_text_hover: var(--isp-dc-lapis-lazuli);
  --ispui-button__sixth_border_hover: var(--isp-dc-lapis-lazuli);
  // active
  --ispui-button__sixth_text_active: var(--isp-dc-fremen);
  --ispui-button__sixth_border_active: var(--isp-dc-fremen);
  // disabled
  --ispui-button__sixth_text_disabled: var(--isp-c-grey-weekdays);
  --ispui-button__sixth_border_disabled: var(--isp-c-inconspicuous);
  --ispui-button__sixth_text_disabled_hover: var(--isp-c-grey-weekdays);
  --ispui-button__sixth_border_disabled_hover: var(--isp-c-inconspicuous);

  // @WARN ispui-button have shadow colors, that not customable!
}
