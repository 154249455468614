:root {
  --isp-form-preloader-z-index: 1001;
  /** show fullscreen code editor on top of all */
  --isp-z-index-form-code-editor: 1000;
  // all the z-indices calculating from progress modal overlay should start
  // from 997 to be on top of the notification group which has 996 hardcoded
  --isp-progress-modal-z-index: 999;
  --isp-progress-modal-overlay-z-index: 998;
  --isp-z-index-drawer: 997;
  // this should be greater than drawer index because form close button should be above the drawer backdrop
  --isp-z-index-form-close-button: 995;
  --ispui-tooltip-z-index: 995;
  --isp-trial-banner-z-index: 200;
  --isp-captcha-z-index: 200;
  --isp-global-search-z-index: 125;
  --isp-form-validation-z-index: 110;
  --isp-autocomplete-select-z-index: 100;
  --isp-progress-bar-z-index: 95;
  --isp-form-header-z-index: 10;
  --isp-form-footer-z-index: 10;
  --isp-global-search-z-index: 125;
  --isp-narrow-banner-danger-z-index: 4;
  --isp-narrow-banner-warning-z-index: 3;
  --isp-narrow-banner-success-z-index: 2;
  --isp-narrow-banner-success-z-index: 1;
}

ispui-progress-bar {
  z-index: 96;
}
