:root {
  --ispui-table-footer__background: var(--isp-dc-monochrome);
  --ispui-table-footer__color: var(--isp-text-color-main);
  --ispui-table__theme-light-color: var(--isp-c-grey-weekdays);
  --ispui-table__theme-light-bg: var(--isp-dc-monochrome);
  --ispui-table__theme-neutral-color: var(--isp-c-grey-weekdays);
  --ispui-table__theme-neutral-bg: var(--isp-c-russian-north);
  --ispui-table__theme-dark-color: var(--isp-c-russian-north);
  --ispui-table__theme-dark-bg: var(--isp-c-malewicz);
  --ispui-table__border: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-table__background: var(--isp-dc-monochrome);
  --ispui-table-checkbox__border: 1px solid var(--isp-c-grey-weekdays);
  --ispui-table-resizer__lever-color: var(--isp-dc-charlie-chaplin);
  --ispui-table-resizer__lever_alert-color: var(--isp-dc-bright-emotions);
  --ispui-table-resizer__lever_info-color: var(--isp-dc-fremen);
  --ispui-table-resizer__splitter-color: var(--isp-dc-charlie-chaplin);
  --ispui-table-resizer__splitter_alert-color: var(--isp-dc-bright-emotions);
  --ispui-table-resizer__splitter_info-color: var(--isp-dc-fremen);
  --ispui-table-kebab__hover-bg: var(--isp-c-wedding-dress);
  --ispui-table-gap-container__gap-bg: var(--isp-c-jon-snow);
  --ispui-table-checkbox__minus-border-color: var(--isp-c-blue-velvet);
  --ispui-table-checkbox__minus-bg-color: var(--isp-c-blue-velvet);
  --ispui-table-checkbox__checked-border-color: var(--isp-c-blue-velvet);
  --ispui-table-card__bg: var(--isp-c-jon-snow);
  --ispui-table-card__box-shadow: var(--isp-box-shadow);
  --ispui-table-card__color: var(--isp-c-malewicz);
  --ispui-table-card__marker-bg: var(--isp-dc-fremen);
  --ispui-table-card__border-bottom: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-table__text-color: var(--isp-c-malewicz);
  --ispui-table__text-color-disabled: var(--isp-c-grey-weekdays);
  --ispui-table__additional-text-color: var(--isp-c-grey-weekdays);
  --ispui-table__action-text-color: var(--isp-dc-fremen);
  --ispui-table__row-disabled-color: var(--isp-c-grey-weekdays);
  --ispui-table__row-disabled-bg: var(--isp-c-russian-north);
  --ispui-table__action-text-error-color: var(--isp-dc-bright-emotions);
  --ispui-table__chosen-row-marker-border: 4px solid var(--isp-c-blue-velvet);

  --ispui-table-pagination__input-border: 1px solid
    var(--isp-dc-charlie-chaplin);
  --ispui-table-pagination__size-var-color: var(--isp-dc-highway-star);
  --ispui-table-pagination__size-var-hover-bg: var(--isp-dc-cult);

  .ispui-table-pagination__input {
    background-color: var(--isp-dc-monochrome);

    &:focus-visible {
      outline: 1px solid var(--isp-dc-fremen);
    }
  }

  .ispui-table-checkbox_disabled {
    background-color: var(--isp-c-russian-north);
  }
  .ispui-table-gap-container__gap_no-data {
    &::after {
      color: var(--isp-c-malewicz);
    }
  }

  .ispui-table__row {
    --ispui-table__row-bg: var(--isp-dc-former);
    --ispui-table__row-hover-bg: var(--isp-dc-the-institute);
    --ispui-table__row-chosen-bg: var(--isp-dc-mass-relay);

    &:nth-child(2n) {
      --ispui-table__row-bg: var(--isp-dc-monochrome);
      --ispui-table__row-chosen-bg: var(--isp-dc-made-in-heaven);
    }
  }
}
